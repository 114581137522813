import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ordergroups"
    }}>{`Ordergroups`}</h1>
    <p>{`An OrderGroup consists of one or more Orders to be executed in the same operation. For example, an Order Group can both enter a product (product sales order) and fill up the card with money in the same pickup.`}</p>
    <h3 {...{
      "id": "the-following-orders-can-now-be-placed-in-an-ordergroup"
    }}>{`The following orders can now be placed in an OrderGroup:`}</h3>
    <h3 {...{
      "id": "mobile-tickets"
    }}>{`Mobile Tickets`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`mobileSingleTicket (Mobile single ticket)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`mobilePeriodicTicket (Mobile period ticket)`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "desfire-card"
    }}>{`Desfire card`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`fareProductSale (Product sales)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PurseReload (Refill travel money)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`autoRenewProduct (Renewal of ticket containing renewal agreement)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`cancelTPurseProductReload (Cancel money refill on card)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`activateDesfireCard (Activation of the card itself.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`updateProfile (Update of card profile, date of birth, price, etc.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`validateProduct (Activation / Validation of a ticket)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`cancelProductActivation (Cancellation of a ticket)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`blockCard (Card blocking)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`automaticTicketSale (Sale of smart ticket)`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "ultralight-cards"
    }}>{`Ultralight Cards`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`ultralightFareProduceSale (Product sales)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`validateUltralightProduct (Activation and validation of ticket)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`cancelUltralightProductSale (Product cancellation)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`cancelFareProductSale (Product Sales Cancellation)`}</p>
      </li>
    </ul>
    <p>{`Each Order in an OrderGroup is submitted to the Plugin for execution together with existing content on the Ticket Medium.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      